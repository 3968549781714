* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

h1.H2heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 144px;
  text-align: center;
  letter-spacing: 0.3em;
  color: #FFFFFF;
}

.subTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
}

.para {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
}

.soical-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 50px 0 0 0;
}

.soical-icon li a {
  width: 60px;
  height: 60px;
  display: flex;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #0481BF;
  backdrop-filter: blur(3px);
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  padding: 14px;
  transition: all 0.5s ease;
}

.soical-icon li a img {
  width: 100%;
  height: 100%;
}