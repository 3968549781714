.coming-soon {
    display: flex;
    justify-content: center;
    padding: 6% 0;
    position: absolute;
    top: 0;
    width: 100%;
    background: linear-gradient(180deg, #000000 30.19%, rgba(0, 0, 0, 0) 100%);
    height: 100%;
}

.soical-icon li a:hover {
    background: #0000003d;
    transform: scale(1.1);
}

.coming-wrapper {
    height: 100vh;
    position: relative;
    overflow-y: auto;
    line-height: 0;
}

.soon-card {
    text-align: center;
}

.coming-wrapper video {
    height: 100%;
    object-fit: cover;
}

.soon-card p.para {
    padding: 40px 0 0 0;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .soon-card img.Site-logo {
        width: 100%;
        max-width: 340px;
    }

    body .soon-card {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    body ul.soical-icon {
        margin: 40px 0 0 0;
    }

    body h1.H2heading {
        font-size: 48px;
        line-height: 72px;
    }

    body section.coming-soon {
        padding: 20% 20px 0 20px;
    }

}


@media screen and (min-width: 768px) and (max-width: 1023px) {

    body .soon-card img.Site-logo {
        width: 100%;
        max-width: 340px;
    }

    body span.subTitle {
        font-size: 20px;
    }

    body ul.soical-icon {
        margin: 40px 0 0 0;
    }

    body h1.H2heading {
        letter-spacing: 0.2em;
        font-size: 60px;
    }

    body .coming-soon {
        padding: 12% 0 0 0;
        background: linear-gradient(180deg, #000000 7%, rgba(0, 0, 0, 0) 40%);
    }
}

@media screen and (min-width: 1024px) and (max-width: 1365px) {
    body h1.H2heading {
        letter-spacing: 0.2em;
        font-size: 70px;
    }

    body .coming-soon {
        padding: 12% 0 0 0;
        background: linear-gradient(180deg, #000000 7%, rgba(0, 0, 0, 0) 40%);
    }
}